import {makeAutoObservable, configure} from "mobx";

configure({
    enforceActions: "never"
});

class global {
    client = {
        id: null,
        isInTeam: false
    }

    game = {
        status: "lobby",
        player: {},
        vote: {
            start: [],
            continue: [],
            quit: [],
            nextRound: [],
            addCard: [],
            holdCards: [],
            useJoker: []
        },
        units: {
            none: "",
            money: "far fa-euro-sign",
            shot: "far fa-glass-water",
            cloth: "far fa-shirt",
            cookie: "far fa-cookie-bite",
            hit: "far fa-hands-clapping"
        }
    }

    desk = {
        cards: [],
        jokers: [],
        stake: 1,
        stakeDecimals: 0,
        stakeReceiver: "loser",
        unit: "shot",
        turn: null,
        allowInput: false,
        usedJoker: null,
        round: 1,
        rounds: 15,
        limit: 21
    }

    team1 = {
        ids: [],
        name: "",
        cards: [],
        jokers: [],
        holdCards: false,
        bank: 0,
        won: 0
    }

    team2 = {
        ids: [],
        name: "",
        cards: [],
        jokers: [],
        holdCards: false,
        bank: 0,
        won: 0
    }

    show = {
        name: false,
        settings: false,
        fullscreen: true
    }

    infobox = {
        showTo: null,
        headline: null,
        text: null,
        button: null,
        action: null
    }

    volume = {
        give_card: 0.5,
        remove_card: 0.2,
        show_card: 0.5,
        show_joker: 0.7,
        hide_joker: 0.5,
        give_joker: 0.4,
        use_joker: 0.3,
        change_limit: 0.5,
        round_winner: 0.5,
        round_loser: 0.5,
        turn: 0.2,
        button: 1.0,
        select: 1.0,
        type: 0.7,
        type_back: 0.4,
        background: 0.1
    }

    backgroundMusic = new Audio("./audio/background.ogg");

    showVotes = (type, jokerName) => {
        let needed = this.team1.ids.length + this.team2.ids.length;
        if (type !== "quit" && needed === 1) needed = 2;
        if (type === "addCard" || type === "holdCards" || type === "useJoker") {
            const team = (this.team1.ids.includes(this.client.id)) ? this.team1 : this.team2;
            needed = team.ids.length;
        }

        let voted = this.game.vote[type]?.length || 0;
        if (type === "useJoker") voted = this.game.vote[type].filter(x => x.split("#")[1] === jokerName).length;

        return (
            (this.game.vote[type]?.length > 0 && needed > 1) ?
                <small className="animation-pulse">{`${voted} / ${needed}`}</small>
                : null
        );
    }

    play = (name, delay) => {
        const audioFile = new Audio(`./audio/${name}.ogg`);
        if (!this.volume[name]) return;
        audioFile.volume = this.volume[name];

        if (delay) {
            setTimeout(() => { audioFile.play(); }, delay);
        } else {
            audioFile.play();
        }
    }

    handleEvent = () => {
        this.play("button");
    }

    setGlobalEvents = () => {
        const buttons = document.querySelectorAll("button");
        
        for (const el of buttons) {
            el.removeEventListener("mousedown", this.handleEvent);
            el.addEventListener("mousedown", this.handleEvent);
        }
    }

    isFullscreen() {
        return document.fullscreenElement !== null;
    }

    enterFullscreen() {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        }
    }
        
    exitFullscreen = () => {
        if (this.isFullscreen()) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const data = new global();
// const data = window.data = new global();

data.backgroundMusic.volume = data.volume.background;
data.backgroundMusic.loop = true;

export default data;