import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";

import Lobby from "./Lobby"; 

import global from "./Global";
import {socket} from "../js/online";

//############################################################################# Animation

const boxInitial = {
    opacity: 0
}

const boxInAnimation = {
    opacity: 1,
    transition: { duration: 0.4 }
}

const boxOutAnimation = {
    opacity: 0,
    transition: { duration: 0.4 }
}

const contentInitial = {
    opacity: 0,
    rotateX: -90
}

const contentInAnimation = {
    opacity: 1,
    rotateX: 0,
    transition: { duration: 0.5, ease: [0.4, 0.68, 0.27, 1] }
}

const contentOutAnimation = {
    opacity: 0,
    rotateX: -90,
    transition: { duration: 0.5, ease: [0.4, 0.68, 0.27, 1] }
}

//#############################################################################

class Infobox extends Component {

    localstate = observable({
        name: "",
        stake: "",
        stakeFormed: true
    });

    submitName() {
        const {client} = global;
        const {name} = this.localstate;

        if (!this.nameIsValid()) return;

        global.show.name = false;
        socket.emit("playerName", client.id, name);
    }

    nameIsValid() {
        const {client, game} = global;
        const {name} = this.localstate;

        const sameNames = Object.values(game.player).filter(x => x.id !== client.id && x.name.toLowerCase().replace(/\s/g, "") === name.toLowerCase().replace(/\s/g, ""));

        return name.length >= 2 && sameNames.length === 0;
    }

    infoboxAction() {
        const {client, infobox} = global;

        if (infobox.action === "close") global.infobox.showTo = null;
        if (infobox.action === "nextRound") socket.emit("vote", "nextRound", client.id);
        if (infobox.action === "quit") socket.emit("vote", "quit", client.id);
    }

    formatStake(stake) {
        stake = stake
            .replace(/^[^0-9,.]*/g, "")
            .replace(/,/g, ".")
            .replace(/\.{2,}/, ".")
            .replace(/^(\d*\.\d*)\..*/g, "$1");
        
        const matchDecimals = stake.match(/\.(\d*)$/);
        const decimals = (matchDecimals) ? matchDecimals[1].length : 0;

        socket.emit("settings", "stake", Number(stake) || 1);
        socket.emit("settings", "stakeDecimals", decimals || 0);
        this.localstate.stakeFormed = true;
    }

    componentDidUpdate() {
        const {setGlobalEvents} = global;
        setGlobalEvents();
    }

    render() {
        const {client, game, desk, team1, team2, show, infobox, backgroundMusic, showVotes, play, enterFullscreen, exitFullscreen} = global;
        const {name, stake, stakeFormed} = this.localstate;

        return (
            <>
                <AnimatePresence>
                    {
                        (game.status === "lobby") && <motion.div key={6} id="lobby" className="overlay" initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation}>
                                <Lobby/>
                            </motion.div>
                        </motion.div>
                    }
                </AnimatePresence>

                <AnimatePresence>
                    {
                        (infobox.showTo && (infobox.showTo === "both" || infobox.showTo.ids.includes(client.id))) &&
                        <motion.div key={5} className="overlay infobox" style={{ backdropFilter: "none" }} initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation}>
                                <div className="flex-col">
                                    <h1>{infobox.headline}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: infobox.text }}></p>
                                </div>
                                <div className="flex-row">
                                    <button disabled={!client.isInTeam} onClick={() => this.infoboxAction()}><span>{infobox.button}</span>{showVotes(infobox.action)}</button>
                                </div>
                            </motion.div>
                        </motion.div>
                    }

                    {
                        game.status === "paused" && <motion.div key={4} className="overlay" initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation}>
                                <h1 className="animation-pulse">Spiel pausiert</h1>
                                <div className="flex-row">
                                    <button disabled={!client.isInTeam} onClick={() => socket.emit("vote", "continue", client.id)}><span>Fortsetzen</span>{showVotes("continue")}</button>
                                    <button disabled={!client.isInTeam} onClick={() => socket.emit("vote", "quit", client.id)}><span>Beenden</span>{showVotes("quit")}</button>
                                </div>
                                {
                                    (game.status !== "lobby" && (team1.ids.length === 0 || team2.ids.length === 0)) &&
                                    <div className="flex-row">{
                                        (client.isInTeam) ?
                                            <p className="animation-pulse">Warte auf beitretende Spieler</p> :
                                            <button onClick={() => socket.emit("joinMissingTeam", client.id)}><span>Fehlenden Team beitreten</span></button>
                                    }</div>
                                }
                            </motion.div>
                        </motion.div>
                    }

                    {
                        show.settings && <motion.div key={3} className="overlay" initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation}>
                                <h1>Einstellungen</h1>
                                <div className="layout-grid">
                                    <span>Runden</span>
                                    <div className="flex-row">
                                        <button className="btn-icon" disabled={desk.rounds <= 1} onClick={() => socket.emit("settings", "rounds", desk.rounds - 2)}><i className="fas fa-chevron-left"></i></button>
                                        <p>{desk.rounds}</p>
                                        <button className="btn-icon" disabled={desk.rounds >= 99} onClick={() => socket.emit("settings", "rounds", desk.rounds + 2)}><i className="fas fa-chevron-right"></i></button>
                                    </div>
                                    <span>Einsatz erhält</span>
                                    <div className="radio-box" onChange={(e) => {
                                        socket.emit("settings", "stakeReceiver", e.target.value);
                                        play("select");
                                    }}>
                                        <label>
                                            <input type="radio" name="stakereceiver" value="winner" checked={desk.stakeReceiver === "winner"} readOnly/>
                                            <p>Gewinner</p>
                                        </label>
                                        <label>
                                            <input type="radio" name="stakereceiver" value="loser" checked={desk.stakeReceiver === "loser"} readOnly/>
                                            <p>Verlierer</p>
                                        </label>
                                    </div>
                                    <span>Einsatz</span>
                                    <div className="flex-row">
                                        <input
                                            type="text"
                                            value={
                                                (stakeFormed) ?
                                                    (typeof desk.stake === "number") ? desk.stake.toFixed(desk.stakeDecimals).replace(".", ",") : desk.stake.replace(/\./g, ",") :
                                                    stake.replace(/\./g, ",")
                                            }
                                            maxLength="5"
                                            placeholder="Einsatz"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                this.localstate.stake = e.target.value;
                                                this.localstate.stakeFormed = false;
                                            }}
                                            onKeyDown={(e) => {
                                                play(`type${(e.key === "Backspace") ? "_back" : ""}`);
                                            }}
                                            onBlur={(e) => this.formatStake(e.target.value)}
                                        />
                                        <div className="radio-box-icons" onChange={(e) => {
                                            socket.emit("settings", "unit", e.target.value);
                                            play("select");
                                        }}>
                                            {
                                                Object.values(game.units).map((x, i) => {
                                                    return (
                                                        <label key={i}>
                                                            <input type="radio" name="unit" value={Object.keys(game.units)[i]} checked={desk.unit === Object.keys(game.units)[i]} readOnly/>
                                                            <p><i className={x}></i></p>
                                                        </label>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <button onClick={() => global.show.settings = false}><span>Passt so</span></button>
                                </div>
                            </motion.div>
                        </motion.div>
                    }

                    {
                        (show.name || game.player[client.id]?.name.length < 2) && <motion.div key={2} className="overlay" initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation} onAnimationComplete={() => {
                                document.querySelector("#input-name")?.focus();
                            }}>
                                <h1>Wer spielt?</h1>
                                <div className="flex-col">
                                    <input
                                        id="input-name" 
                                        type="text"
                                        value={name}
                                        maxLength="20"
                                        placeholder="..."
                                        autoComplete="off"
                                        onChange={(e) => this.localstate.name = e.target.value}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") this.submitName();
                                            play(`type${(e.key === "Backspace") ? "_back" : ""}`);
                                        }}
                                    />
                                </div>
                                <div className="flex-col">
                                    <button 
                                        disabled={!this.nameIsValid()}
                                        onClick={() => this.submitName()}
                                    >
                                        <span>Los geht's</span>
                                    </button>
                                </div>
                            </motion.div>
                        </motion.div>
                    }

                    {
                        show.fullscreen && <motion.div key={1} className="overlay" initial={boxInitial} animate={boxInAnimation} exit={boxOutAnimation}>
                            <motion.div initial={contentInitial} animate={contentInAnimation} exit={contentOutAnimation}>
                                <h1>Vollbild gefällig?</h1>
                                <div className="flex-row">
                                    <button onClick={() => {
                                        global.show.fullscreen = false;
                                        enterFullscreen();
                                        document.querySelector("#input-name")?.focus();
                                        backgroundMusic.play();
                                    }}><span>Ja</span></button>
                                    <button onClick={() => {
                                        global.show.fullscreen = false;
                                        exitFullscreen();
                                        document.querySelector("#input-name")?.focus();
                                        backgroundMusic.play();
                                    }}><span>Nein</span></button>
                                </div>
                            </motion.div>
                        </motion.div>
                    }
                </AnimatePresence>
            </>
        );
    }
}

export default observer(Infobox);