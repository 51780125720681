import React, {Component} from "react";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";

import global from "./Global";
import {socket} from "../js/online";

//############################################################################# Animation

const initial = {
    opacity: 0,
    y: 30,
}

const joinAnimation = {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 100 },
}

const leaveAnimation = {
    opacity: 0,
    x: -50,
    height: 0,
    padding: 0,
    transition: {
        opacity: { duration: 0.4, ease: [0.5, 0, 0.5, 1] },
        x: { duration: 0.5, ease: [0.5, 0, 0.5, 1] },
        height: { duration: 0.5, ease: [0.5, 0, 0.5, 1] },
        padding: { duration: 0.5, ease: [0.5, 0, 0.5, 1] },
    },
}

//#############################################################################

class Lobby extends Component {
    
    joinTeam(team = null) {
        const {client} = global;

        socket.emit("joinTeam", client.id, team);
    }

    render() {
        const {client, game, team1, team2, backgroundMusic, showVotes} = global;

        return (
            <>
                <h1 id="headline"><i className="fas fa-cards"></i>21<i className="fas fa-cards"></i></h1>
                <div className="flex-col">
                    <section>
                        <div className="flex-col">
                            <button onClick={() => this.joinTeam(1)} disabled={team1.ids.includes(client.id)}><span>Beitreten</span></button>
                        </div>
                        <div className="flex-col"/>
                        <div className="flex-col">
                            <button onClick={() => this.joinTeam(2)} disabled={team2.ids.includes(client.id)}><span>Beitreten</span></button>
                        </div>
                    </section>
                    <section>
                        <div className="flex-col style-frame">
                            <ul>
                                <AnimatePresence>{
                                    team1.ids.map(id => {
                                        const player = game.player[id];
                                        if (!player) return false;
                                        
                                        return (
                                            <motion.li
                                                key={id}
                                                className={(id === client.id) ? "clickable" : null}
                                                onClick={(id === client.id) ? () => global.show.name = true : null}
                                                initial={initial}
                                                animate={joinAnimation}
                                                exit={leaveAnimation}
                                            >
                                                <i className="fas fa-user"></i>{player.name}
                                            </motion.li>
                                        );
                                    })
                                }</AnimatePresence>
                            </ul>
                        </div>
                        <div className="flex-col">
                            <h1>VS</h1>
                        </div>
                        <div className="flex-col style-frame">
                            <ul>{
                                <AnimatePresence>{
                                    team2.ids.map(id => {
                                        const player = game.player[id];
                                        if (!player) return false;
                                        return (
                                            <motion.li 
                                                key={id}
                                                className={(id === client.id) ? "clickable" : null}
                                                onClick={(id === client.id) ? () => global.show.name = true : null}
                                                initial={initial}
                                                animate={joinAnimation}
                                                exit={leaveAnimation}
                                            >
                                                <i className="fas fa-user"></i>{player.name}
                                            </motion.li>
                                        );
                                    })
                                }</AnimatePresence>
                            }</ul>
                        </div>
                    </section>
                </div>
                <section>
                    <button onClick={() => socket.emit("vote", "start", client.id)} disabled={!client.isInTeam || team1.ids.length === 0 || team2.ids.length === 0}>
                        <i className="fas fa-gamepad-alt"></i>
                        <span>Spielen</span>
                        {showVotes("start")}
                    </button>
                </section>
                <button className="btn-icon"/>
                <button className="btn-icon" onClick={() => {
                    (backgroundMusic.paused) ? backgroundMusic.play() : backgroundMusic.pause();
                    this.forceUpdate();
                }}>
                    <i className={`fas fa-music${(backgroundMusic.paused) ? "" : "-slash"}`}></i>
                </button>
                <button className="btn-icon" onClick={() => {
                    global.show.settings = true;
                }}><i className="fas fa-gears"></i></button>
                <button className="btn-icon" onClick={() => global.show.fullscreen = true}><i className="fas fa-expand"></i></button>
            </>
        );
    }
}

export default observer(Lobby);