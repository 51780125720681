import React, {Component} from "react";
import CountUp from "react-countup";

class CountTo extends Component {
    state = {
        className: this.props?.className || null,
        decimals: 0,
        from: 0,
        to: this.props?.to || 0,
        duration: 2.5
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.className !== this.props.className ||
            prevProps.decimals !== this.props.decimals ||
            prevProps.to !== this.props.to
        ) {
            this.setState({
                className: this.props?.className || null,
                decimals: this.props.decimals || 0,
                from: prevProps.to || 0,
                to: this.props.to || 0,
                duration: this.props?.duration || 2.5
            });
        }
    }

    render() {
        const {className, decimals, from, to, duration} = this.state;

        return <CountUp 
            className={className}
            decimals={decimals}
            decimal=","
            start={from}
            end={to}
            duration={duration}
        />;
    }
}

export default CountTo;