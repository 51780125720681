import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";
import CountTo from "./CountTo";
import DeskTeam1 from "./DeskTeam1";
import DeskTeam2 from "./DeskTeam2";

import global from "./Global";
import {socket} from "../js/online";

//############################################################################# Animation

const animation = {
    card: {
        initial: {
            x: window.outerWidth,
            rotateX: 70,
            rotateY: 180
        },
        in: {
            x: 0,
            rotateX: 0,
            rotateY: 0,
            transition: {
                x: { ease: [0.3, 0.93, 0.35, 1], duration: 1.2 },
                rotateX: { ease: [0.3, 0.93, 0.35, 1], duration: 1.5 },
                rotateY: { ease: [0.3, 0.93, 0.35, 1], duration: 1.5 }
            }
        },
        out: {
            y: (document.querySelector("#desk-team1")?.offsetHeight || 350) + 20,
            width: 0,
            margin: 0,
            transition: {
                y: { ease: [1, 0.12, 0.8, 0.75], duration: 0.5 },
                width: { ease: [0.5, 0, 0.5, 1], duration: 0.5, delay: 0.8 },
                margin: { ease: [0.5, 0, 0.5, 1], duration: 0.5, delay: 0.8 }
            }
        }
    },

    joker: {
        in: {
            x: 0,
            scale: 1,
            transition: {
                x: { ease: [0.3, 0.93, 0.35, 1], duration: 1, delay: 1 },
                scale: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3, delay: 2 }
            }
        },
        out: {
            scale: 0,
            width: 0,
            margin: 0,
            padding: 0,
            transition: {
                scale: { ease: [1, 0.12, 0.8, 0.75], duration: 0.8, delay: 1 },
                width: { ease: [0.5, 0, 0.5, 1], duration: 0.5, delay: 1.8 },
                margin: { ease: [0.5, 0, 0.5, 1], duration: 0.5, delay: 1.8 },
                padding: { ease: [0.5, 0, 0.5, 1], duration: 0.5, delay: 1.8 }
            }
        }
    },

    turn: {
        in: {
            opacity: 1,
            transition: { duration: 0.4 }
        },
        out: {
            opacity: 0,
            transition: { duration: 0.4 }
        }
    },

    jokerPreview: {
        initial: {
            opacity: 0,
            rotateX: -70,
            rotateY: 70
        },
        in: {
            opacity: 1,
            rotateX: 0,
            rotateY: 0,
            transition: { ease: [0.3, 0.93, 0.35, 1], duration: 0.6 }
        },
        out: {
            opacity: 0,
            rotateX: 70,
            transition: { ease: [0.3, 0.93, 0.35, 1], duration: 0.6 }
        }
    },

    jokerUsed: {
        initial: {
            opacity: 0,
            rotateY: 720,
            y: 0
        },
        in: {
            opacity: 1,
            rotateY: 0,
            transition: {
                opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5, delay: 0.5 },
                rotateY: { ease: [0.3, 0.93, 0.35, 1], duration: 2.5, delay: 0.5 },
            }
        },
        out: {
            opacity: 0,
            y: 50,
            transition: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 }
        }
    },

    sumPlaceholder: {
        initial: {
            opacity: 0,
            x: -30
        },
        in: {
            opacity: 1,
            x: 0,
            transition: { ease: [0.3, 0.93, 0.35, 1], duration: 0.8 }
        },
        out: {
            opacity: 0,
            x: -30,
            transition: { ease: [0.3, 0.93, 0.35, 1], duration: 0.8 }
        }
    }
}

//#############################################################################

class Desk extends Component {

    localstate = observable({
        selectedJoker: null
    });

    useJoker() {
        const {client, play} = global;
        const {selectedJoker} = this.localstate;

        socket.emit("vote", "useJoker", `${client.id}#${selectedJoker.name}`);

        play("button");
    }

    componentDidUpdate() {
        const {game, client} = global;
        const {selectedJoker} = this.localstate;
        let {team1, team2} = global;

        if (team1.ids.includes(client.id)) [team1, team2] = [team2, team1];

        if (game.status === "lobby") this.localstate.selectedJoker = null;
        if (selectedJoker && !team2.jokers.map(x => x.name).includes(selectedJoker.name)) this.localstate.selectedJoker = null;
    }

    render() {
        const {client, game, desk, showVotes, play} = global;
        const {selectedJoker} = this.localstate;
        let {team1, team2} = global;

        if (team1.ids.includes(client.id)) [team1, team2] = [team2, team1];

        return (<>
            <div id="desk">
                <DeskTeam1 team1={team1} team2={team2} animation={animation} selectedJoker={selectedJoker}/>

                <div id="desk-board">
                    <motion.div id="jokers-team1" className="jokers" animate="show">
                        <AnimatePresence>{
                            team1.jokers.map(x => {
                                return (
                                    <motion.div
                                        key={x.name}
                                        className="joker style-joker"
                                        initial={{ x: -window.outerWidth / 2, scale: 1.1 }}
                                        animate={animation.joker.in}
                                        exit={animation.joker.out}
                                    >
                                        <i className="fas fa-crown"></i>
                                    </motion.div>
                                );
                            })
                        }</AnimatePresence>
                    </motion.div>

                    <div id="board-info">
                        <section id="stake">
                            <CountTo decimals={desk.stakeDecimals} to={desk.stake}/>
                            {desk.unit !== "none" && <i className={game.units[desk.unit]}></i>}
                        </section>
                        <section>
                            <span>Runde</span>
                            <div>
                                <span>{desk.round}</span>
                                <p>/</p>
                                <span>{desk.rounds}</span>
                            </div>
                        </section>
                    </div>

                    <motion.div id="jokers-team2" className="jokers">
                        <AnimatePresence>{
                            team2.jokers.map(x => {
                                const pulseAnimation = (game.vote.useJoker.map(x => x.split("#")[1]).includes(x.name) && team2.turn) ? "animation-pulse" : "";

                                return (
                                    <motion.div
                                        key={x.name}
                                        className={`joker style-joker ${pulseAnimation}`}
                                        initial={{ x: window.outerWidth / 2, scale: 1.1 }}
                                        animate={animation.joker.in}
                                        exit={animation.joker.out}
                                        onClick={() => {
                                            this.localstate.selectedJoker = x;
                                            play("show_joker");
                                        }}
                                    >
                                        <i className="fas fa-crown"></i>
                                        <h1>{x.title}</h1>
                                    </motion.div>
                                );
                            })
                        }</AnimatePresence>
                    </motion.div>
                </div>

                <DeskTeam2 team1={team1} team2={team2} animation={animation} selectedJoker={selectedJoker}/>
            </div>

            <AnimatePresence>{
                selectedJoker && <motion.div
                    id="joker-preview"
                    initial={{ opacity: 0 }}
                    animate={animation.turn.in}
                    exit={animation.turn.out}
                    onClick={() => {
                        this.localstate.selectedJoker = null;
                        if (team2.turn) socket.emit("vote", "useJoker", `${client.id}#${selectedJoker.name}`, false);
                        play("hide_joker");
                    }}
                >
                    <motion.div
                        className="joker style-joker-preview"
                        initial={animation.jokerPreview.initial}
                        animate={animation.jokerPreview.in}
                        exit={animation.jokerPreview.out}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i className="fas fa-crown"></i>
                        <h1>{selectedJoker.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: selectedJoker.description }}></p>
                        <button
                            disabled={!client.isInTeam || team1.turn || !desk.allowInput || !team2.jokers.map(x => x.name).includes(selectedJoker.name)}
                            onClick={() => this.useJoker()}
                        >
                            <span>Einsetzen</span>
                            {team2.turn && showVotes("useJoker", selectedJoker?.name)}
                        </button>
                    </motion.div>
                </motion.div>
            }</AnimatePresence>
        </>);
    }
}

export default observer(Desk);