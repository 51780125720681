import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";
import CountTo from "./CountTo";

import global from "./Global";
import {socket} from "../js/online";

//#############################################################################

class DeskTeam2 extends Component {

    localstate = observable({
        cardDegrees: []
    });

    randomDegree(min = -6, max = 6) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    render() {
        const {client, game, desk, backgroundMusic, showVotes} = global;
        const {team1, team2, animation} = this.props;

        const team2Sum = team2.cards.reduce((a, b) => a + b, 0);

        return (
            <div id="desk-team2">
                <div className="team-name">{team2.name}</div>

                <div className="cards">
                    <AnimatePresence>{
                        team2.cards.map((x, i) => {
                            return (
                                <motion.div key={x} className="card" initial={animation.card.initial} animate={animation.card.in} exit={animation.card.out} onAnimationStart={() => {
                                    this.localstate.cardDegrees.splice(i, 1, this.randomDegree());
                                }}>
                                    <div className="front style-card" style={{ transform: `rotateZ(${this.localstate.cardDegrees[i] || 0}deg)` }}>
                                        <span>{x}</span>
                                        <div className="back style-card"><span>?</span></div>
                                    </div>
                                </motion.div>
                            );
                        })
                    }</AnimatePresence>
                </div>

                <div className="bank">
                    <CountTo decimals={desk.stakeDecimals} to={team2.bank}/>
                    {desk.unit !== "none" && <i className={game.units[desk.unit]}></i>}
                </div>

                <div className="sum">
                    <CountTo className={(team2Sum === desk.limit) ? "perfect" : (team2Sum > desk.limit) ? "above" : ""} to={team2Sum}/>
                    <p>/</p>
                    <CountTo to={desk.limit}/>
                </div>

                <AnimatePresence>{
                    team1.turn && <motion.div className="turn" initial={{ opacity: 0 }} animate={animation.turn.in} exit={animation.turn.out}>
                        <div className="flex-col animation-pulse">
                            <h1>{team1.name}</h1>
                            <span>{(team1.ids.length === 1) ? "ist" : "sind"} am Zug</span>
                        </div>
                    </motion.div>
                }</AnimatePresence>

                <AnimatePresence>{
                    (desk.usedJoker && team2.turn) && <motion.div className="joker-used" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }} exit={animation.turn.out}>
                        <motion.div className="joker style-joker-preview" initial={animation.jokerUsed.initial} animate={animation.jokerUsed.in} exit={animation.jokerUsed.out}>
                            <i className="fas fa-crown"></i>
                            <h1>{desk.usedJoker.title}</h1>
                        </motion.div>
                    </motion.div>
                }</AnimatePresence>

                <button className="btn-icon" onClick={() => global.show.fullscreen = true}><i className="fas fa-expand"></i></button>
                <button className="btn-icon" onClick={() => {
                    (backgroundMusic.paused) ? backgroundMusic.play() : backgroundMusic.pause();
                    this.forceUpdate();
                }}>
                    <i className={`fas fa-music${(backgroundMusic.paused) ? "" : "-slash"}`}></i>
                </button>

                <div id="desk-buttons">
                    <button
                        disabled={!client.isInTeam || team1.turn || !desk.allowInput}
                        onClick={() => socket.emit("vote", "addCard", client.id)}
                    >
                        <span>Karte</span>
                        {team2.turn && showVotes("addCard")}
                    </button>                    
                    <button
                        disabled={!client.isInTeam || team1.turn || !desk.allowInput}
                        onClick={() => socket.emit("vote", "holdCards", client.id)}
                    >
                        <span>Halten</span>
                        {team2.turn && showVotes("holdCards")}
                    </button>
                </div>
            </div>
        );
    }
}

export default observer(DeskTeam2);