import React, {Component} from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {motion, AnimatePresence} from "framer-motion";
import CountTo from "./CountTo";

import global from "./Global";
import {socket} from "../js/online";

//#############################################################################

class DeskTeam1 extends Component {

    localstate = observable({
        cardDegrees: []
    });

    randomDegree(min = -6, max = 6) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    render() {
        const {client, game, desk} = global;
        const {team1, team2, animation} = this.props;

        let team1Sum = team1.cards.reduce((a, b) => a + b, 0);
        if (game.status !== "end" && client.isInTeam) team1Sum -= (team1.cards[0] || 0);

        return (
            <div id="desk-team1">
                <div className="team-name">{team1.name}</div>

                <div className="cards">
                    <AnimatePresence>{
                        team1.cards.map((x, i) => {
                            const revealFirstCard = (i === 0 && game.status === "end") || !client.isInTeam;
                            return (
                                <motion.div key={x} className="card" initial={animation.card.initial} animate={animation.card.in} exit={animation.card.out} onAnimationStart={(e) => {
                                    this.localstate.cardDegrees.splice(i, 1, this.randomDegree());
                                }}>
                                    <div className="front style-card" style={{ transform: `rotateZ(${this.localstate.cardDegrees[i] || 0}deg) ${(revealFirstCard) ? "rotateY(-180deg)" : ""}`}}>
                                        <span>{(i === 0 && client.isInTeam) ? "?" : x}</span>
                                        <div className="back style-card"><span>{(revealFirstCard) ? x : null}</span></div>
                                    </div>
                                </motion.div>
                            );
                        })
                    }</AnimatePresence>
                </div>

                <div className="bank">
                    <CountTo decimals={desk.stakeDecimals} to={team1.bank}/>
                    {desk.unit !== "none" && <i className={game.units[desk.unit]}></i>}
                </div>
                
                <div className="sum">
                    <AnimatePresence>{
                        (game.status !== "end" && client.isInTeam) && <motion.p initial={animation.sumPlaceholder.initial} animate={animation.sumPlaceholder.in} exit={animation.sumPlaceholder.out}>? + </motion.p>
                    }</AnimatePresence>
                    <CountTo className={(game.status === "end" || !client.isInTeam) ? (team1Sum === desk.limit) ? "perfect" : (team1Sum > desk.limit) ? "above" : "" : ""} to={team1Sum}/>
                    <p>/</p>
                    <CountTo to={desk.limit}/>
                </div>

                <AnimatePresence>{
                    team2.turn && <motion.div className="turn" initial={{ opacity: 0 }} animate={animation.turn.in} exit={animation.turn.out}>
                        <div className="flex-col animation-pulse">
                            <h1>{(client.isInTeam) ? "Du" : team2.name}</h1>
                            <span>{(client.isInTeam) ? "bist" : (team2.ids.length === 1) ? "ist" : "sind"} am Zug</span>
                        </div>
                    </motion.div>
                }</AnimatePresence>

                <AnimatePresence>{
                    (desk.usedJoker && team1.turn) && <motion.div className="joker-used" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.5, delay: 0.5 } }} exit={animation.turn.out}>
                        <motion.div className="joker style-joker-preview" initial={animation.jokerUsed.initial} animate={animation.jokerUsed.in} exit={animation.jokerUsed.out}>
                            <i className="fas fa-crown"></i>
                            <h1>{desk.usedJoker.title}</h1>
                        </motion.div>
                    </motion.div>
                }</AnimatePresence>

                <button className="btn-icon" disabled={!client.isInTeam} onClick={() => socket.emit("gameStatus", "paused")}><i className="fas fa-pause"></i></button>
            </div>
        );
    }
}

export default observer(DeskTeam1);